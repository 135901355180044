import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      color: 'whiteAlpha.700',
      fontFamily: 'Cerebri Sans',
      fontSize: '17px',
      minHeight: '56px',
    },
  },
  variants: {
    outline: {
      field: {
        border: '2px solid',
        borderColor: 'whiteAlpha.100',
        background: 'transparent',
        borderRadius: 0,
        ':focus': {
          borderColor: '#008F9D',
          boxShadow: 'none',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};

export default Input;
