import { Box } from '@chakra-ui/react';
import {
  render,
  MARK_LINK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
} from 'storyblok-rich-text-react-renderer';

import { H1, H2, H3, H4, Link, P, Image } from '@/elements';
import { IStoryblokComponentProps } from '@/types/storyblok';

export const defaultBlokResolver = (name: string) => {
  return <div>{name}</div>;
};

const LinkComponent = (
  children,
  { href, target }: { href: string; target: string }
) => (
  <Link color='white' href={href} target={target} variant='simple'>
    {children}
  </Link>
);

const HeadingComponent = (children, { level }) => {
  switch (level) {
    case 1:
      return <H1 mb='lg'>{children}</H1>;
    case 2:
      return <H2 mb='md'>{children}</H2>;
    case 3:
      return <H3 mb='sm'>{children}</H3>;
    case 4:
      return <H4 mb='xs'>{children}</H4>;
    default:
      return <P mb='md'>{children}</P>;
  }
};

const ImageComponent = (_children, { src, alt, title }) => (
  <Image alt={alt} src={src} title={title} />
);

const ParagraphComponent = (children) => <P mb='md'>{children}</P>;

const RichText = ({ blok }: IStoryblokComponentProps): JSX.Element => (
  <Box m={4} margin='0 auto' maxW='1000px'>
    {render(blok.content, {
      markResolvers: {
        [MARK_LINK]: LinkComponent,
      },
      nodeResolvers: {
        [NODE_HEADING]: HeadingComponent,
        [NODE_IMAGE]: ImageComponent,
        [NODE_PARAGRAPH]: ParagraphComponent,
      },
      defaultBlokResolver,
    })}
  </Box>
);

export default RichText;
