export default {
  variants: {
    simple: {
      th: {
        textAlign: 'start !important',
        fontSize: 'base',
        color: 'white.50',
        fontWeight: '400',
        fontFamily: 'Cerebri Sans',
        textTransform: 'none',
        borderBottomWidth: '1px',
        borderColor: 'white.10',
        padding: '16px 24px'
      },
      td: {
        textAlign: 'start !important',
        color: 'white.100',
        padding: '16px 24px'
      },
      tbody: {
        tr: {
          '&:not(:last-child)': {
            'th, td': {
              borderBottomWidth: '1px',
              borderColor: 'white.10',
            },
          },
          '&:last-child': {
            'th, td': {
              borderBottomWidth: 0,
            },
          },
        },
      },
    },
  },
};
