import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  global: (props) => ({
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      fontFamily: 'body',
      fontSize: 'base',
      color: 'white',
      height: '100%',
    },

    body: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      margin: '0px',
      backgroundColor: 'brand.dark',
      color: 'white',
    },

    // Utils
    '.disable-scroll': {
      overflowY: 'hidden',
    },

    '#__next': {
      height: '100%',
    },

    // React-Dates-Styles

    // border around days
    '.DayPicker__withBorder, .CalendarMonthGrid, .CalendarMonth': {
      boxShadow: 'none',
      background: 'none',
    },

    '.CalendarDay': {
      border: 'none',
      background: 'none',
      color: 'white',
      _hover: {
        borderWidth: '0px',
        background: 'teal.500',
        color: 'white',
      },
    },
    '.CalendarDay__selected': {
      background: 'teal.500',
      color: '#fff',
      borderWidth: '0px',
    },
    '.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:hover': {
      border: 'none',
      background: 'transparent',
      color: 'grey',
    },

    '.CalendarMonth_caption': {
      textAlign: 'left',
      paddingLeft: '11px',
    },
    '.CalendarMonth_caption > strong ': {
      fontWeight: 200,
      color: 'white',
      fontSize: '17px',
      textAlign: 'left',
    },

    '.DayPickerNavigation_button, .DayPickerNavigation_button:hover': {
      border: 'none',
      background: 'none',
    },
    '.DayPickerNavigation_1 > &:first-child': {
      position: 'absolute',
      right: '60px',
      top: '21px',
    },
    '.DayPickerNavigation_1 > &:last-child': {
      position: 'absolute',
      right: '25px',
      top: '21px',
    },
  }),
};

export default styles;
