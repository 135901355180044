const colors = {
  brand: {
    mid: '#D7CBC3',
    light: '#E6E1DD',
    dark: '#1F1E1E',
    accent: '#03656F',
  },
  red: {
    400: '#FF4545',
  },
  white: {
    '10': 'rgba(230, 225, 221, 0.12)',
    '50': 'rgba(230, 225, 221, 0.5)',
    '70': 'rgba(230, 225, 221, 0.7)',
    '100': '#E6E1DD',
  },
  teal: {
    '50': '#EDF6F7',
    '100': '#CEE6E9',
    '200': '#AED7DB',
    '300': '#8EC7CC',
    '400': '#008F9D',
    '500': '#03656F',
    '600': '#204346',
    '700': '#102123',
    '800': '#0c191a',
    '900': '#070e0f',
  },
  yellow: {
    '400': '#FFD053',
  },
  green: {
    '400': '#87AF55',
  },
};

export default colors;
