interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: Partial<IFirebaseConfig> | undefined =
  process.env.NEXT_PUBLIC_FIREBASE_CONFIG &&
  JSON.parse(
    Buffer.from(
      `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
      'base64'
    ).toString()
  );

export default {
  hostname: process.env.NEXT_PUBLIC_DOMAIN || 'http://localhost:3000',
  env: process.env.NODE_ENV,
  appEnv: process.env.APP_ENV,
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  prismic: {
    repo: process.env.NEXT_PUBLIC_PRISMIC_REPO,
    accessToken: process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN,
  },
  prodDomain: process.env.NEXT_PUBLIC_PROD_DOMAIN,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  title: "Ted's Health",
  tileColor: '#ffffff',
  firebaseConfig,
  klaviyo: {
    privateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY,
    publicApiKey: process.env.NEXT_PUBLIC_KLAVIYO_API_KEY,
    lists: {
      notifyWhenLive: process.env.NEXT_PUBLIC_KLAVIYO_LIST_NOTIFY,
    },
  },
  enableTestHelper: process.env.NEXT_PUBLIC_ENABLE_TEST_HELPER === 'true',

  // Server-side config (not exposed to the client)
  nextAuthUrl: process.env.NEXTAUTH_URL,
  nextAuthJwtSecret: process.env.NEXTAUTH_JWT_SECRET,
  cms: {
    isrTimeInSeconds: process.env.NEXT_CMS_ISR_SECONDS,
  },
};
