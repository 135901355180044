import { storyblokEditable } from '@storyblok/react';

import { H2 } from '@/elements';
import { IStoryblokComponentProps } from '@/types/storyblok.d';

const SBTeaser = ({ blok }: IStoryblokComponentProps): JSX.Element => {
  return (
    <H2 color='white' {...storyblokEditable(blok)} m={4}>
      {blok.headline}
    </H2>
  );
};

export default SBTeaser;
