import Button from './button';
import Checkbox from './checkbox';
import Form from './form';
import FormLabel from './formLabel';
import Input from './input';
import Link from './link';
import Modal from './modal';
import Table from './table';
import Tabs from './tabs';

export default {
  Button,
  Form,
  Input,
  FormLabel,
  Tabs,
  Modal,
  Link,
  Checkbox,
  Table,
};
