export default {
  baseStyle: {
    control: {
      borderColor: 'whiteAlpha.100',
      borderRadius: 0,

      _checked: {
        boxShadow: 'none',
        borderColor: 'none',
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'teal.400',
      },
      _focusVisible: {
        boxShadow: 'none',
      },
    },
    label: {
      color: 'whiteAlpha.700',

      _hover: {
        color: 'white.100',
      },
    },
  },
  defaultProps: {
    colorScheme: 'teal',
    size: 'base',
  },
  sizes: {
    sm: {
      control: { height: '24px', width: '24px' },
      label: { fontSize: 'sm' },
      icon: { fontSize: '12px' },
    },
    base: {
      control: { height: '28px', width: '28px' },
      label: { fontSize: 'base', marginLeft: 'semiLg' },
      icon: { fontSize: '14px' },
    },
    md: {
      control: { height: '32px', width: '32px' },
      label: { fontSize: 'md' },
      icon: { fontSize: '16px' },
    },
    lg: {
      control: { height: '36px', width: '36px' },
      label: { fontSize: 'lg' },
      icon: { fontSize: '18px' },
    },
  },
};
