import { StyleFunctionProps } from '@chakra-ui/theme-tools';

export default {
  baseStyle: {
    lineHeight: 2,
    color: 'black',
    borderRadius: 0,
    padding: '32px 15px',
    fontWeight: 500,

    _focus: {
      boxShadow: 'none',
    },
  },
  defaultProps: {
    colorScheme: 'teal',
  },
  variants: {
    icon: {
      width: '48px',
      height: '48px',
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
      padding: '16px 16px',
    },
    outline: {
      _hover: {
        bg: 'transparent',
        borderColor: 'teal.400',
      },
    },
    solid: (props: StyleFunctionProps) => ({
      _hover: {
        bg: 'teal.400',
        boxShadow: props.withoutShadow
          ? ''
          : '0px 3.68101px 40.4911px rgba(3, 101, 111, 0.5)',
      },
    }),
  },
};
