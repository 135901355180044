import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button as ChakraButton } from '@chakra-ui/react';

import ChevronLeft from '@/components/Icons/ChevronLeft';

export const Button = ChakraButton;

export const BackButton = (props) => {
  const { children } = props;

  return (
    <Button
      colorScheme='white'
      flexShrink={0}
      fontFamily='Made Outer Sans'
      fontSize='26px'
      fontWeight='100'
      padding='0px 0px !important'
      textDecoration='underline'
      textDecorationColor='white.100'
      textDecorationThickness='1px'
      textUnderlineOffset='8px'
      variant='link'
      {...props}
    >
      <ChevronLeft height='16px' mr='15px' width='12px' />
      {children}
    </Button>
  );
};

export const NextButton = (props) => {
  const { children } = props;

  return (
    <Button
      colorScheme='white'
      fontFamily='Made Outer Sans'
      fontSize='26px'
      fontWeight='100'
      padding='0px 0px'
      textDecoration='underline'
      textDecorationColor='white.100'
      textDecorationThickness='1px'
      textUnderlineOffset='8px'
      variant='link'
      {...props}
    >
      {children}
      <ChevronRightIcon style={{ marginLeft: '15px' }} />
    </Button>
  );
};
