import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

// eslint-disable-next-line import/prefer-default-export
export const Card: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      bg='linear-gradient(143.08deg, #2C2B2B 0%, #232323 39.06%), #1F1E1E'
      p='semiXl'
      width='100%'
      {...rest}
    >
      {children}
    </Box>
  );
};
