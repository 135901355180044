import { useCallback } from 'react';

import '../../public/fonts/style.css';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { AnimatePresence } from 'framer-motion';
import { getSession, SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import Script from 'next/script';

import SBComponentsMapping from '@/components/CMS';
import config from '@/config';
import { AuthProvider } from '@/context/AuthContext';
import { GoogleMapsProvider } from '@/context/GoogleMapsContext';
import { useApollo } from '@/graphql/apollo';
import { CustomSession } from '@/server/models/auth';
import theme from '@/theme';

storyblokInit({
  accessToken: `${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}`,
  // bridge: false,
  // apiOptions: {  },
  use: [apiPlugin],
  components: SBComponentsMapping,
});

export default function App({
  Component,
  pageProps: { session: sessionProp, ...pageProps },
}): JSX.Element {
  const getAuthToken = useCallback(async () => {
    const session = (await getSession()) as CustomSession;
    return session?.idToken || null;
  }, []);

  const apolloClient = useApollo(pageProps.initialApolloState, getAuthToken);

  const resetCache = useCallback(async () => {
    await apolloClient.resetStore();
  }, [apolloClient]);

  return (
    <>
      {config.googleAnalytics.id && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalytics.id}`}
            strategy='afterInteractive'
          />
          <Script id='google-analytics' strategy='afterInteractive'>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${config.googleAnalytics.id}', {
              page_path: window.location.pathname,
              });
            `}
          </Script>
        </>
      )}
      <Sentry.ErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <SessionProvider session={sessionProp}>
            <AuthProvider clearCache={resetCache}>
              <GoogleMapsProvider>
                <ChakraProvider resetCSS theme={theme}>
                  <>
                    <Head>
                      <title>{config.title}</title>
                      <meta
                        content='width=device-width, initial-scale=1'
                        name='viewport'
                      />
                      <meta content='ie=edge' httpEquiv='x-ua-compatible' />
                    </Head>
                    <AnimatePresence exitBeforeEnter>
                      <Component {...pageProps} />
                    </AnimatePresence>
                  </>
                </ChakraProvider>
              </GoogleMapsProvider>
            </AuthProvider>
          </SessionProvider>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}
