import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AddPaymentSourceResponse = {
  __typename?: 'AddPaymentSourceResponse';
  card: CardDetails;
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
};

export type AppointmentModel = {
  __typename?: 'AppointmentModel';
  createdAt: Scalars['DateTime'];
  doctor?: Maybe<Doctor>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  patientId: Scalars['String'];
  start: Scalars['DateTime'];
  videoUrl: Scalars['String'];
};

export type AvailabilitySlotModel = {
  __typename?: 'AvailabilitySlotModel';
  doctorId: Scalars['String'];
  end: Scalars['String'];
  start: Scalars['String'];
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  address?: Maybe<StripeAddress>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type BioMarkerResultAttributes = {
  __typename?: 'BioMarkerResultAttributes';
  abnormal_flag: Scalars['String'];
  code: Scalars['String'];
  failure_reason: Scalars['String'];
  notes: Array<Scalars['String']>;
  reference_ranges: Array<ReferenceRange>;
  result_operator: Scalars['String'];
  result_type: Scalars['String'];
  result_unit: Scalars['String'];
  result_value: Scalars['String'];
  successful: Scalars['Boolean'];
};

export type BiomarkerResult = {
  __typename?: 'BiomarkerResult';
  attributes: BioMarkerResultAttributes;
};

export type BiomarkersData = {
  __typename?: 'BiomarkersData';
  testProfiles: Array<TestProfileModel>;
};

export type BloodTestOrder = {
  __typename?: 'BloodTestOrder';
  completedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  fulfillmentOrders: Array<FulfillmentOrder>;
  id: Scalars['Float'];
  pathwayRecord: PathwayRecord;
  pathwayRecordId: Scalars['Float'];
  pathwayRecordStepUid: Scalars['String'];
  status?: Maybe<BloodTestOrderStatus>;
  tests: Array<OrderTest>;
  thrivaOrderId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BloodTestOrderDetails = {
  __typename?: 'BloodTestOrderDetails';
  fulfillmentOrders: Array<FulfillmentOrder>;
  tests: Array<OrderTest>;
};

/** Current status of BloodTestOrder test */
export enum BloodTestOrderStatus {
  Ordered = 'ordered',
  Processed = 'processed',
  ProcessingFailed = 'processing_failed'
}

export type BookAppointmentInput = {
  comments?: InputMaybe<Scalars['String']>;
  doctorId: Scalars['String'];
  end: Scalars['String'];
  pathwayRecordId: Scalars['Float'];
  pathwayStepUid: Scalars['String'];
  start: Scalars['String'];
};

export type CardDetails = {
  __typename?: 'CardDetails';
  brand: Scalars['String'];
  exp_month: Scalars['Float'];
  exp_year: Scalars['Float'];
  last4: Scalars['String'];
};

export type Consultation = {
  __typename?: 'Consultation';
  appointment: AppointmentModel;
  id: Scalars['Float'];
  pathwayRecordId: Scalars['Float'];
  pathwayStepUid: Scalars['String'];
};

export type ConsultationQueryOptions = {
  pathwayRecordId: Scalars['Float'];
  pathwayStepUid: Scalars['String'];
};

export type DispatchPathwayEventInput = {
  data?: InputMaybe<Scalars['String']>;
  pathwayRecordId: Scalars['Float'];
  stepUid: Scalars['String'];
  type: PathwayEventType;
};

export type Doctor = {
  __typename?: 'Doctor';
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type FulfillmentOrder = {
  __typename?: 'FulfillmentOrder';
  id: Scalars['String'];
  status: ThrivaOrderFulfilmentStatus;
};

export type GetUsersQueryInput = {
  email: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type IdentityCheck = {
  __typename?: 'IdentityCheck';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  result?: Maybe<IdentityCheckResult>;
  status?: Maybe<IdentityCheckStatus>;
  updatedAt: Scalars['DateTime'];
};

/** The identity check result */
export enum IdentityCheckResult {
  Clear = 'clear',
  Consider = 'consider',
  Unidentified = 'unidentified'
}

/**
 * The identity check status:
 *     in_progress: We are currently processing the check.
 *     awaiting_applicant: The applicant has not yet submitted the applicant form, either because they have not started filling the form out or because they have started but have not finished.
 *     complete: All reports for the applicant have been completed or withdrawn.
 *     withdrawn: The check has been withdrawn.
 *     paused: The check is paused until you (the client) switch it on manually. Special case used by clients who wants to collect data and run the checks when they want and not immediately.
 *     reopened: Insufficient or inconsistent information was provided by the applicant, and the report has been bounced back for further information.
 *
 */
export enum IdentityCheckStatus {
  AwaitingApplicant = 'awaiting_applicant',
  Complete = 'complete',
  InProgress = 'in_progress',
  Paused = 'paused',
  Reopened = 'reopened',
  Withdrawn = 'withdrawn'
}

export type Intent = {
  __typename?: 'Intent';
  clientSecret: Scalars['String'];
  id: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  id?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addPaymentSource: AddPaymentSourceResponse;
  applyDiscountCode: PaymentIntentResultModel;
  bookAppointment: Consultation;
  checkStatusOfOnfidoCheck: Scalars['Boolean'];
  completeQuestionnaire: QuestionnaireResponses;
  createPathwayRecord: PathwayRecord;
  createPaymentIntent: PaymentIntentResultModel;
  deleteDeviceToken?: Maybe<Scalars['Boolean']>;
  deletePathwayEvents: Scalars['Boolean'];
  deletePaymentMethod: ResponseWithError;
  dispatchPathwayEvents: Array<PathwayStepEvent>;
  forgotPassword: Scalars['Boolean'];
  login: UserResponse;
  orderBloodTest: BloodTestOrder;
  purchaseProduct: PaymentIntentResultModel;
  register: UserResponse;
  resetPassword: Scalars['Boolean'];
  saveDeviceToken?: Maybe<Scalars['Boolean']>;
  saveResponse: QuestionnaireResponses;
  seedAdminUser: Scalars['Boolean'];
  setupIntent: Intent;
  startQuestionnaire: QuestionnaireResponses;
  syncBloodTestStatus: BloodTestOrder;
  updateProfile: UserModel;
  updateUserRole: User;
  uploadAmsResults: Scalars['Boolean'];
  uploadCompletedBloodTestResults: Scalars['Boolean'];
  verifyEmail: Scalars['Boolean'];
  verifyIdentity: IdentityCheck;
};


export type MutationAddPaymentSourceArgs = {
  stripeToken: Scalars['String'];
};


export type MutationApplyDiscountCodeArgs = {
  code: Scalars['String'];
  paymentIntentId: Scalars['String'];
};


export type MutationBookAppointmentArgs = {
  options: BookAppointmentInput;
};


export type MutationCheckStatusOfOnfidoCheckArgs = {
  checkId: Scalars['String'];
};


export type MutationCompleteQuestionnaireArgs = {
  questionnaireResponsesId: Scalars['Float'];
};


export type MutationCreatePathwayRecordArgs = {
  pathwayUid: Scalars['String'];
};


export type MutationCreatePaymentIntentArgs = {
  productIds: Array<Scalars['String']>;
};


export type MutationDeleteDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationDeletePathwayEventsArgs = {
  ids: Array<Scalars['Float']>;
};


export type MutationDeletePaymentMethodArgs = {
  sourceId: Scalars['String'];
};


export type MutationDispatchPathwayEventsArgs = {
  events: Array<DispatchPathwayEventInput>;
  pathwayRecordId: Scalars['Float'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  options: LoginInput;
};


export type MutationOrderBloodTestArgs = {
  pathwayRecordId: Scalars['Float'];
  stepUid: Scalars['String'];
};


export type MutationPurchaseProductArgs = {
  paymentIntentId: Scalars['String'];
  paymentMethodId: Scalars['String'];
};


export type MutationRegisterArgs = {
  options: RegisterInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSaveDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationSaveResponseArgs = {
  questionnaireResponsesId: Scalars['Float'];
  response: QuestionResponseInput;
};


export type MutationStartQuestionnaireArgs = {
  startQuestionnaireInput: StartQuestionnaireInput;
};


export type MutationSyncBloodTestStatusArgs = {
  id: Scalars['Float'];
};


export type MutationUpdateProfileArgs = {
  options: UpdateProfileInput;
};


export type MutationUpdateUserRoleArgs = {
  options: UpdateUserRoleInput;
};


export type MutationUploadAmsResultsArgs = {
  id: Scalars['Float'];
};


export type MutationUploadCompletedBloodTestResultsArgs = {
  id: Scalars['Float'];
};


export type MutationVerifyEmailArgs = {
  id: Scalars['String'];
};

export type OrderTest = {
  __typename?: 'OrderTest';
  id: Scalars['String'];
  resultSet?: Maybe<ResultsModel>;
  resultSetId?: Maybe<Scalars['String']>;
  status: ThrivaTestResultStatus;
};

export type PagedOrdersResponse = {
  __typename?: 'PagedOrdersResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<BloodTestOrder>;
  totalCount: Scalars['Float'];
};

export type PagedQueryInput = {
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type PagedUsersResponse = {
  __typename?: 'PagedUsersResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<UserModel>;
  totalCount: Scalars['Float'];
};

export type Pathway = {
  __typename?: 'Pathway';
  name: Scalars['String'];
  steps: Array<PathwayStep>;
  uid: Scalars['String'];
};

/** The events that can occurring on a pathway */
export enum PathwayEventType {
  AmsCompleted = 'AMS_COMPLETED',
  AmsQuestionAnswered = 'AMS_QUESTION_ANSWERED',
  AmsStarted = 'AMS_STARTED',
  BloodTestDelivered = 'BLOOD_TEST_DELIVERED',
  BloodTestDispatched = 'BLOOD_TEST_DISPATCHED',
  BloodTestOrdered = 'BLOOD_TEST_ORDERED',
  BloodTestPurchased = 'BLOOD_TEST_PURCHASED',
  BloodTestSampleProcessed = 'BLOOD_TEST_SAMPLE_PROCESSED',
  BloodTestSampleProcessedFailed = 'BLOOD_TEST_SAMPLE_PROCESSED_FAILED',
  BloodTestSampleReceived = 'BLOOD_TEST_SAMPLE_RECEIVED',
  BloodTestSampleWaiting = 'BLOOD_TEST_SAMPLE_WAITING',
  ConsultationBooked = 'CONSULTATION_BOOKED',
  ConsultationCancelled = 'CONSULTATION_CANCELLED',
  ConsultationFulfilled = 'CONSULTATION_FULFILLED',
  IdVerificationFailed = 'ID_VERIFICATION_FAILED',
  IdVerificationPassed = 'ID_VERIFICATION_PASSED',
  IdVerificationSubmitted = 'ID_VERIFICATION_SUBMITTED',
  SubscriptionActivated = 'SUBSCRIPTION_ACTIVATED',
  SubscriptionCancelled = 'SUBSCRIPTION_CANCELLED',
  SubscriptionCreated = 'SUBSCRIPTION_CREATED',
  SubscriptionPaid = 'SUBSCRIPTION_PAID',
  SubscriptionRefunded = 'SUBSCRIPTION_REFUNDED'
}

export type PathwayRecord = {
  __typename?: 'PathwayRecord';
  bloodTestOrders: Array<BloodTestOrder>;
  createdAt: Scalars['DateTime'];
  events: Array<PathwayStepEvent>;
  id: Scalars['Float'];
  pathwayUid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type PathwayStep = {
  __typename?: 'PathwayStep';
  events: Array<PathwayEventType>;
  name: Scalars['String'];
  uid: Scalars['String'];
  waitFor?: Maybe<Array<PathwayStepDependency>>;
};

export type PathwayStepDependency = {
  __typename?: 'PathwayStepDependency';
  events: Array<PathwayEventType>;
  stepUid: Scalars['String'];
};

export type PathwayStepEvent = {
  __typename?: 'PathwayStepEvent';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['Float'];
  stepUid: Scalars['String'];
  type: PathwayEventType;
};

export type PaymentIntentResultModel = {
  __typename?: 'PaymentIntentResultModel';
  clientSecret?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  totalPrice?: Maybe<Scalars['Float']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billing_details?: Maybe<BillingDetails>;
  card: CardDetails;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PaymentMethodsResponse = {
  __typename?: 'PaymentMethodsResponse';
  error?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};

export type PriceModel = {
  __typename?: 'PriceModel';
  currency: Scalars['String'];
  id: Scalars['String'];
  unit_amount?: Maybe<Scalars['Float']>;
  unit_amount_decimal?: Maybe<Scalars['String']>;
};

export type ProductModel = {
  __typename?: 'ProductModel';
  /** Alias value from metadata */
  alias?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  object: Scalars['String'];
  price?: Maybe<PriceModel>;
  url?: Maybe<Scalars['String']>;
};

export type ProductQueryResponse = ProductModel | ResponseWithError;

export type Query = {
  __typename?: 'Query';
  availabilities: Array<AvailabilitySlotModel>;
  bloodTestOrderDetails: BloodTestOrderDetails;
  bloodTestOrders: PagedOrdersResponse;
  consultation?: Maybe<Consultation>;
  fetchPathwayRecord: PathwayRecord;
  onfidoSdkToken?: Maybe<Scalars['String']>;
  pathwayRecord?: Maybe<PathwayRecord>;
  pathways: Array<Pathway>;
  paymentMethods: PaymentMethodsResponse;
  product: ProductQueryResponse;
  questionnaire: Questionnaire;
  questionnaireResponses?: Maybe<QuestionnaireResponses>;
  self: UserModel;
  user: QueryUserResponse;
  users: PagedUsersResponse;
};


export type QueryAvailabilitiesArgs = {
  options: QueryAvailabilitiesInput;
};


export type QueryBloodTestOrderDetailsArgs = {
  orderId: Scalars['Float'];
};


export type QueryBloodTestOrdersArgs = {
  options?: InputMaybe<PagedQueryInput>;
};


export type QueryConsultationArgs = {
  id?: InputMaybe<Scalars['Float']>;
  options?: InputMaybe<ConsultationQueryOptions>;
};


export type QueryFetchPathwayRecordArgs = {
  id: Scalars['Float'];
};


export type QueryPathwayRecordArgs = {
  pathwayUid: Scalars['String'];
};


export type QueryProductArgs = {
  alias: Scalars['String'];
};


export type QueryQuestionnaireArgs = {
  questionnaireUid: Scalars['String'];
};


export type QueryQuestionnaireResponsesArgs = {
  questionnaireResponsesId?: InputMaybe<Scalars['Float']>;
  searchOptions?: InputMaybe<QuestionnaireResponsesSearchOptions>;
};


export type QueryUserArgs = {
  options: QueryUserInput;
};


export type QueryUsersArgs = {
  options?: InputMaybe<GetUsersQueryInput>;
};

export type QueryAvailabilitiesInput = {
  start: Scalars['DateTime'];
};

export type QueryUserInput = {
  id: Scalars['ID'];
};

export type QueryUserResponse = {
  __typename?: 'QueryUserResponse';
  error?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

export type Question = {
  __typename?: 'Question';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  options?: Maybe<Array<QuestionOption>>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  label: Scalars['String'];
  questions?: Maybe<Array<Question>>;
  value: Scalars['String'];
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  questionId: Scalars['String'];
  response: QuestionResponseValue;
};

export type QuestionResponseInput = {
  questionId: Scalars['String'];
  response: QuestionResponseValueInput;
};

export type QuestionResponseValue = {
  __typename?: 'QuestionResponseValue';
  value: Scalars['String'];
};

export type QuestionResponseValueInput = {
  value: Scalars['String'];
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  questions: Array<Question>;
  title: Scalars['String'];
  uid: Scalars['String'];
};

export type QuestionnaireResponses = {
  __typename?: 'QuestionnaireResponses';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  pathwayRecordId: Scalars['Float'];
  pathwayStepUid: Scalars['String'];
  questionnaireUid: Scalars['String'];
  responseScores?: Maybe<Scalars['JSON']>;
  responses?: Maybe<Array<QuestionResponse>>;
  scores?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
};

export type QuestionnaireResponsesSearchOptions = {
  pathwayRecordId: Scalars['Float'];
  pathwayStepUid: Scalars['String'];
  questionnaireUid: Scalars['String'];
};

export type ReferenceRange = {
  __typename?: 'ReferenceRange';
  lower_bound: Scalars['Float'];
  name: Scalars['String'];
  upper_bound: Scalars['Float'];
};

export type RegisterInput = {
  dob: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type RelationshipsData = {
  __typename?: 'RelationshipsData';
  biomakerResults: BiomarkersData;
  testProfiles: TestProfilesData;
};

export type ResponseWithError = {
  __typename?: 'ResponseWithError';
  error?: Maybe<Scalars['String']>;
};

export type ResultsData = {
  __typename?: 'ResultsData';
  id: Scalars['String'];
  relationships: RelationshipsData;
  type: Scalars['String'];
};

export type ResultsModel = {
  __typename?: 'ResultsModel';
  biomarkerResults: Array<BiomarkerResult>;
  biomarkerStatus: Scalars['String'];
  data: ResultsData;
  testProfileResults: Array<TestProfileResult>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
};

export type ShippingAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
};

export type StartQuestionnaireInput = {
  pathwayRecordId: Scalars['Float'];
  pathwayStepUid: Scalars['String'];
  questionnaireUid: Scalars['String'];
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TestProfileModel = {
  __typename?: 'TestProfileModel';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TestProfileResult = {
  __typename?: 'TestProfileResult';
  attributes: TestProfileResultAttributes;
};

export type TestProfileResultAttributes = {
  __typename?: 'TestProfileResultAttributes';
  code: Scalars['String'];
  collection_methods: Array<Scalars['String']>;
  name: Scalars['String'];
  sample_type: Scalars['String'];
};

export type TestProfilesData = {
  __typename?: 'TestProfilesData';
  testProfiles: Array<TestProfileModel>;
};

/** A current status of thriva order */
export enum ThrivaOrderFulfilmentStatus {
  Complete = 'complete',
  Delivering = 'delivering',
  Fulfilling = 'fulfilling'
}

/** A current status of thriva test */
export enum ThrivaTestResultStatus {
  AwaitingSampleAtLab = 'awaiting_sample_at_lab',
  Complete = 'complete',
  ProcessingSample = 'processing_sample'
}

export type UpdateProfileInput = {
  dob?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<ShippingAddressInput>;
};

export type UpdateUserRoleInput = {
  roles: Array<UserRole>;
  userId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  dob?: Maybe<Scalars['DateTime']>;
  firebaseId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  semblePatientId?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<ShippingAddress>;
  stripeId?: Maybe<Scalars['String']>;
};

export type UserModel = {
  __typename?: 'UserModel';
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  identityChecks: Array<IdentityCheck>;
  lastName?: Maybe<Scalars['String']>;
  semblePatientId?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<ShippingAddress>;
  stripeId?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  error?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

/** User Role */
export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type AppointmentFieldsFragment = { __typename?: 'AppointmentModel', id?: string | null, videoUrl: string, start: any, end: any, patientId: string, createdAt: any, doctor?: { __typename?: 'Doctor', id?: string | null, fullName?: string | null } | null };

export type SyncOrderStatusMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type SyncOrderStatusMutation = { __typename?: 'Mutation', syncBloodTestStatus: { __typename?: 'BloodTestOrder', id: number } };

export type BookAppointmentMutationVariables = Exact<{
  options: BookAppointmentInput;
}>;


export type BookAppointmentMutation = { __typename?: 'Mutation', bookAppointment: { __typename?: 'Consultation', id: number, pathwayStepUid: string, pathwayRecordId: number, appointment: { __typename?: 'AppointmentModel', id?: string | null, videoUrl: string, start: any, end: any, patientId: string, createdAt: any, doctor?: { __typename?: 'Doctor', id?: string | null, fullName?: string | null } | null } } };

export type DispatchPathwayEventMutationVariables = Exact<{
  pathwayRecordId: Scalars['Float'];
  events: Array<DispatchPathwayEventInput> | DispatchPathwayEventInput;
}>;


export type DispatchPathwayEventMutation = { __typename?: 'Mutation', dispatchPathwayEvents: Array<{ __typename?: 'PathwayStepEvent', id: number, stepUid: string, createdAt: any, type: PathwayEventType, data?: any | null }> };

export type DeletePathwayEventsMutationVariables = Exact<{
  ids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type DeletePathwayEventsMutation = { __typename?: 'Mutation', deletePathwayEvents: boolean };

export type CreatePathwayRecordMutationVariables = Exact<{
  pathwayUid: Scalars['String'];
}>;


export type CreatePathwayRecordMutation = { __typename?: 'Mutation', createPathwayRecord: { __typename?: 'PathwayRecord', pathwayUid: string, id: number, createdAt: any, updatedAt: any, events: Array<{ __typename?: 'PathwayStepEvent', id: number, createdAt: any, stepUid: string, type: PathwayEventType, data?: any | null }> } };

export type StartQuestionnaireMutationVariables = Exact<{
  startQuestionnaireInput: StartQuestionnaireInput;
}>;


export type StartQuestionnaireMutation = { __typename?: 'Mutation', startQuestionnaire: { __typename?: 'QuestionnaireResponses', id: number, responses?: Array<{ __typename?: 'QuestionResponse', questionId: string, response: { __typename?: 'QuestionResponseValue', value: string } }> | null } };

export type CompleteQuestionnaireMutationVariables = Exact<{
  questionnaireResponsesId: Scalars['Float'];
}>;


export type CompleteQuestionnaireMutation = { __typename?: 'Mutation', completeQuestionnaire: { __typename?: 'QuestionnaireResponses', id: number, completedAt?: any | null, questionnaireUid: string, pathwayStepUid: string, pathwayRecordId: number, responses?: Array<{ __typename?: 'QuestionResponse', questionId: string, response: { __typename?: 'QuestionResponseValue', value: string } }> | null } };

export type SaveResponseMutationVariables = Exact<{
  questionnaireResponsesId: Scalars['Float'];
  response: QuestionResponseInput;
}>;


export type SaveResponseMutation = { __typename?: 'Mutation', saveResponse: { __typename?: 'QuestionnaireResponses', id: number, responses?: Array<{ __typename?: 'QuestionResponse', questionId: string, response: { __typename?: 'QuestionResponseValue', value: string } }> | null } };

export type SetSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type SetSetupIntentMutation = { __typename?: 'Mutation', setupIntent: { __typename?: 'Intent', clientSecret: string } };

export type DeletePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type DeletePaymentMethodMutation = { __typename?: 'Mutation', deletePaymentMethod: { __typename?: 'ResponseWithError', error?: string | null } };

export type CreatePaymentIntentMutationVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreatePaymentIntentMutation = { __typename?: 'Mutation', createPaymentIntent: { __typename?: 'PaymentIntentResultModel', clientSecret?: string | null, id: string, totalPrice?: number | null } };

export type ApplyDiscountMutationVariables = Exact<{
  code: Scalars['String'];
  paymentIntentId: Scalars['String'];
}>;


export type ApplyDiscountMutation = { __typename?: 'Mutation', applyDiscountCode: { __typename?: 'PaymentIntentResultModel', clientSecret?: string | null, id: string, totalPrice?: number | null, discountAmount?: number | null, error?: string | null } };

export type PurchaseProductMutationVariables = Exact<{
  paymentIntentId: Scalars['String'];
  paymentMethodId: Scalars['String'];
}>;


export type PurchaseProductMutation = { __typename?: 'Mutation', purchaseProduct: { __typename?: 'PaymentIntentResultModel', clientSecret?: string | null } };

export type RegisterUserMutationVariables = Exact<{
  options: RegisterInput;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', register: { __typename?: 'UserResponse', error?: string | null, idToken?: string | null, user?: { __typename?: 'UserModel', firstName: string, firebaseId: string } | null } };

export type VerifyIdentityMutationVariables = Exact<{ [key: string]: never; }>;


export type VerifyIdentityMutation = { __typename?: 'Mutation', verifyIdentity: { __typename?: 'IdentityCheck', id: string, updatedAt: any, createdAt: any, status?: IdentityCheckStatus | null } };

export type UpdateUserProfileMutationVariables = Exact<{
  options: UpdateProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'UserModel', id: number } };

export type VerifyEmailMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: boolean };

export type BloodTestOrdersQueryVariables = Exact<{
  options?: InputMaybe<PagedQueryInput>;
}>;


export type BloodTestOrdersQuery = { __typename?: 'Query', bloodTestOrders: { __typename?: 'PagedOrdersResponse', totalCount: number, results: Array<{ __typename?: 'BloodTestOrder', createdAt: any, id: number, tests: Array<{ __typename?: 'OrderTest', status: ThrivaTestResultStatus }>, fulfillmentOrders: Array<{ __typename?: 'FulfillmentOrder', status: ThrivaOrderFulfilmentStatus }>, pathwayRecord: { __typename?: 'PathwayRecord', user?: { __typename?: 'User', id: number } | null } }> } };

export type BloodTestOrderDetailsQueryVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type BloodTestOrderDetailsQuery = { __typename?: 'Query', bloodTestOrderDetails: { __typename?: 'BloodTestOrderDetails', tests: Array<{ __typename?: 'OrderTest', id: string, status: ThrivaTestResultStatus }>, fulfillmentOrders: Array<{ __typename?: 'FulfillmentOrder', id: string, status: ThrivaOrderFulfilmentStatus }> } };

export type AvailabilitiesQueryVariables = Exact<{
  options: QueryAvailabilitiesInput;
}>;


export type AvailabilitiesQuery = { __typename?: 'Query', availabilities: Array<{ __typename?: 'AvailabilitySlotModel', start: string, end: string, doctorId: string }> };

export type ConsultationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Float']>;
  options?: InputMaybe<ConsultationQueryOptions>;
}>;


export type ConsultationQuery = { __typename?: 'Query', consultation?: { __typename?: 'Consultation', id: number, pathwayStepUid: string, pathwayRecordId: number, appointment: { __typename?: 'AppointmentModel', id?: string | null, videoUrl: string, start: any, end: any, patientId: string, createdAt: any, doctor?: { __typename?: 'Doctor', id?: string | null, fullName?: string | null } | null } } | null };

export type PathwaysQueryVariables = Exact<{ [key: string]: never; }>;


export type PathwaysQuery = { __typename?: 'Query', pathways: Array<{ __typename?: 'Pathway', uid: string, name: string, steps: Array<{ __typename?: 'PathwayStep', uid: string, name: string, events: Array<PathwayEventType>, waitFor?: Array<{ __typename?: 'PathwayStepDependency', stepUid: string, events: Array<PathwayEventType> }> | null }> }> };

export type PathwayRecordQueryVariables = Exact<{
  pathwayUid: Scalars['String'];
}>;


export type PathwayRecordQuery = { __typename?: 'Query', pathwayRecord?: { __typename?: 'PathwayRecord', id: number, createdAt: any, updatedAt: any, pathwayUid: string, events: Array<{ __typename?: 'PathwayStepEvent', id: number, stepUid: string, createdAt: any, type: PathwayEventType, data?: any | null }> } | null };

export type QuestionnaireQueryVariables = Exact<{
  questionnaireUid: Scalars['String'];
}>;


export type QuestionnaireQuery = { __typename?: 'Query', questionnaire: { __typename?: 'Questionnaire', uid: string, questions: Array<{ __typename?: 'Question', id: string, title: string, description?: string | null, type: string, options?: Array<{ __typename?: 'QuestionOption', value: string, label: string, questions?: Array<{ __typename?: 'Question', id: string, title: string, description?: string | null, type: string, options?: Array<{ __typename?: 'QuestionOption', value: string, label: string }> | null }> | null }> | null }> } };

export type QuestionnaireResponsesQueryVariables = Exact<{
  questionnaireResponsesId: Scalars['Float'];
}>;


export type QuestionnaireResponsesQuery = { __typename?: 'Query', questionnaireResponses?: { __typename?: 'QuestionnaireResponses', id: number, completedAt?: any | null, questionnaireUid: string, pathwayStepUid: string, pathwayRecordId: number, responses?: Array<{ __typename?: 'QuestionResponse', questionId: string, response: { __typename?: 'QuestionResponseValue', value: string } }> | null } | null };

export type SearchQuestionnaireResponsesQueryVariables = Exact<{
  searchOptions: QuestionnaireResponsesSearchOptions;
}>;


export type SearchQuestionnaireResponsesQuery = { __typename?: 'Query', questionnaireResponses?: { __typename?: 'QuestionnaireResponses', id: number, completedAt?: any | null, questionnaireUid: string, pathwayStepUid: string, pathwayRecordId: number, responses?: Array<{ __typename?: 'QuestionResponse', questionId: string, response: { __typename?: 'QuestionResponseValue', value: string } }> | null } | null };

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentMethodsQuery = { __typename?: 'Query', paymentMethods: { __typename?: 'PaymentMethodsResponse', paymentMethods?: Array<{ __typename?: 'PaymentMethod', id?: string | null, card: { __typename?: 'CardDetails', exp_month: number, exp_year: number, brand: string, last4: string } }> | null } };

export type ProductQueryVariables = Exact<{
  alias: Scalars['String'];
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'ProductModel', id: string, name: string, alias?: string | null, metadata?: string | null, caption?: string | null, description?: string | null, images: Array<string>, object: string, url?: string | null, price?: { __typename?: 'PriceModel', currency: string, unit_amount?: number | null, unit_amount_decimal?: string | null } | null } | { __typename?: 'ResponseWithError', error?: string | null } };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self: { __typename?: 'UserModel', id: number, firstName: string, lastName?: string | null, email?: string | null, firebaseId: string, stripeId?: string | null, dob?: any | null, shippingAddress?: { __typename?: 'ShippingAddress', address1: string, address2?: string | null, city: string, postalCode: string } | null } };

export type IdentityChecksQueryVariables = Exact<{ [key: string]: never; }>;


export type IdentityChecksQuery = { __typename?: 'Query', self: { __typename?: 'UserModel', identityChecks: Array<{ __typename?: 'IdentityCheck', id: string, status?: IdentityCheckStatus | null, result?: IdentityCheckResult | null, updatedAt: any }> } };

export type OnfidoSdkTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type OnfidoSdkTokenQuery = { __typename?: 'Query', onfidoSdkToken?: string | null };

export const AppointmentFieldsFragmentDoc = gql`
    fragment AppointmentFields on AppointmentModel {
  id
  doctor {
    id
    fullName
  }
  videoUrl
  start
  end
  patientId
  createdAt
}
    `;
export const SyncOrderStatusDocument = gql`
    mutation SyncOrderStatus($id: Float!) {
  syncBloodTestStatus(id: $id) {
    id
  }
}
    `;
export type SyncOrderStatusMutationFn = Apollo.MutationFunction<SyncOrderStatusMutation, SyncOrderStatusMutationVariables>;

/**
 * __useSyncOrderStatusMutation__
 *
 * To run a mutation, you first call `useSyncOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncOrderStatusMutation, { data, loading, error }] = useSyncOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<SyncOrderStatusMutation, SyncOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncOrderStatusMutation, SyncOrderStatusMutationVariables>(SyncOrderStatusDocument, options);
      }
export type SyncOrderStatusMutationHookResult = ReturnType<typeof useSyncOrderStatusMutation>;
export type SyncOrderStatusMutationResult = Apollo.MutationResult<SyncOrderStatusMutation>;
export type SyncOrderStatusMutationOptions = Apollo.BaseMutationOptions<SyncOrderStatusMutation, SyncOrderStatusMutationVariables>;
export const BookAppointmentDocument = gql`
    mutation BookAppointment($options: BookAppointmentInput!) {
  bookAppointment(options: $options) {
    id
    pathwayStepUid
    pathwayRecordId
    appointment {
      ...AppointmentFields
    }
  }
}
    ${AppointmentFieldsFragmentDoc}`;
export type BookAppointmentMutationFn = Apollo.MutationFunction<BookAppointmentMutation, BookAppointmentMutationVariables>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBookAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<BookAppointmentMutation, BookAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(BookAppointmentDocument, options);
      }
export type BookAppointmentMutationHookResult = ReturnType<typeof useBookAppointmentMutation>;
export type BookAppointmentMutationResult = Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<BookAppointmentMutation, BookAppointmentMutationVariables>;
export const DispatchPathwayEventDocument = gql`
    mutation dispatchPathwayEvent($pathwayRecordId: Float!, $events: [DispatchPathwayEventInput!]!) {
  dispatchPathwayEvents(pathwayRecordId: $pathwayRecordId, events: $events) {
    id
    stepUid
    createdAt
    type
    data
  }
}
    `;
export type DispatchPathwayEventMutationFn = Apollo.MutationFunction<DispatchPathwayEventMutation, DispatchPathwayEventMutationVariables>;

/**
 * __useDispatchPathwayEventMutation__
 *
 * To run a mutation, you first call `useDispatchPathwayEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchPathwayEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchPathwayEventMutation, { data, loading, error }] = useDispatchPathwayEventMutation({
 *   variables: {
 *      pathwayRecordId: // value for 'pathwayRecordId'
 *      events: // value for 'events'
 *   },
 * });
 */
export function useDispatchPathwayEventMutation(baseOptions?: Apollo.MutationHookOptions<DispatchPathwayEventMutation, DispatchPathwayEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DispatchPathwayEventMutation, DispatchPathwayEventMutationVariables>(DispatchPathwayEventDocument, options);
      }
export type DispatchPathwayEventMutationHookResult = ReturnType<typeof useDispatchPathwayEventMutation>;
export type DispatchPathwayEventMutationResult = Apollo.MutationResult<DispatchPathwayEventMutation>;
export type DispatchPathwayEventMutationOptions = Apollo.BaseMutationOptions<DispatchPathwayEventMutation, DispatchPathwayEventMutationVariables>;
export const DeletePathwayEventsDocument = gql`
    mutation deletePathwayEvents($ids: [Float!]!) {
  deletePathwayEvents(ids: $ids)
}
    `;
export type DeletePathwayEventsMutationFn = Apollo.MutationFunction<DeletePathwayEventsMutation, DeletePathwayEventsMutationVariables>;

/**
 * __useDeletePathwayEventsMutation__
 *
 * To run a mutation, you first call `useDeletePathwayEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePathwayEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePathwayEventsMutation, { data, loading, error }] = useDeletePathwayEventsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeletePathwayEventsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePathwayEventsMutation, DeletePathwayEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePathwayEventsMutation, DeletePathwayEventsMutationVariables>(DeletePathwayEventsDocument, options);
      }
export type DeletePathwayEventsMutationHookResult = ReturnType<typeof useDeletePathwayEventsMutation>;
export type DeletePathwayEventsMutationResult = Apollo.MutationResult<DeletePathwayEventsMutation>;
export type DeletePathwayEventsMutationOptions = Apollo.BaseMutationOptions<DeletePathwayEventsMutation, DeletePathwayEventsMutationVariables>;
export const CreatePathwayRecordDocument = gql`
    mutation createPathwayRecord($pathwayUid: String!) {
  createPathwayRecord(pathwayUid: $pathwayUid) {
    pathwayUid
    id
    createdAt
    updatedAt
    events {
      id
      createdAt
      stepUid
      type
      data
    }
  }
}
    `;
export type CreatePathwayRecordMutationFn = Apollo.MutationFunction<CreatePathwayRecordMutation, CreatePathwayRecordMutationVariables>;

/**
 * __useCreatePathwayRecordMutation__
 *
 * To run a mutation, you first call `useCreatePathwayRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePathwayRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPathwayRecordMutation, { data, loading, error }] = useCreatePathwayRecordMutation({
 *   variables: {
 *      pathwayUid: // value for 'pathwayUid'
 *   },
 * });
 */
export function useCreatePathwayRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreatePathwayRecordMutation, CreatePathwayRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePathwayRecordMutation, CreatePathwayRecordMutationVariables>(CreatePathwayRecordDocument, options);
      }
export type CreatePathwayRecordMutationHookResult = ReturnType<typeof useCreatePathwayRecordMutation>;
export type CreatePathwayRecordMutationResult = Apollo.MutationResult<CreatePathwayRecordMutation>;
export type CreatePathwayRecordMutationOptions = Apollo.BaseMutationOptions<CreatePathwayRecordMutation, CreatePathwayRecordMutationVariables>;
export const StartQuestionnaireDocument = gql`
    mutation startQuestionnaire($startQuestionnaireInput: StartQuestionnaireInput!) {
  startQuestionnaire(startQuestionnaireInput: $startQuestionnaireInput) {
    id
    responses {
      questionId
      response {
        value
      }
    }
  }
}
    `;
export type StartQuestionnaireMutationFn = Apollo.MutationFunction<StartQuestionnaireMutation, StartQuestionnaireMutationVariables>;

/**
 * __useStartQuestionnaireMutation__
 *
 * To run a mutation, you first call `useStartQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuestionnaireMutation, { data, loading, error }] = useStartQuestionnaireMutation({
 *   variables: {
 *      startQuestionnaireInput: // value for 'startQuestionnaireInput'
 *   },
 * });
 */
export function useStartQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<StartQuestionnaireMutation, StartQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartQuestionnaireMutation, StartQuestionnaireMutationVariables>(StartQuestionnaireDocument, options);
      }
export type StartQuestionnaireMutationHookResult = ReturnType<typeof useStartQuestionnaireMutation>;
export type StartQuestionnaireMutationResult = Apollo.MutationResult<StartQuestionnaireMutation>;
export type StartQuestionnaireMutationOptions = Apollo.BaseMutationOptions<StartQuestionnaireMutation, StartQuestionnaireMutationVariables>;
export const CompleteQuestionnaireDocument = gql`
    mutation completeQuestionnaire($questionnaireResponsesId: Float!) {
  completeQuestionnaire(questionnaireResponsesId: $questionnaireResponsesId) {
    id
    completedAt
    questionnaireUid
    pathwayStepUid
    pathwayRecordId
    responses {
      questionId
      response {
        value
      }
    }
  }
}
    `;
export type CompleteQuestionnaireMutationFn = Apollo.MutationFunction<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>;

/**
 * __useCompleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCompleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeQuestionnaireMutation, { data, loading, error }] = useCompleteQuestionnaireMutation({
 *   variables: {
 *      questionnaireResponsesId: // value for 'questionnaireResponsesId'
 *   },
 * });
 */
export function useCompleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>(CompleteQuestionnaireDocument, options);
      }
export type CompleteQuestionnaireMutationHookResult = ReturnType<typeof useCompleteQuestionnaireMutation>;
export type CompleteQuestionnaireMutationResult = Apollo.MutationResult<CompleteQuestionnaireMutation>;
export type CompleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>;
export const SaveResponseDocument = gql`
    mutation saveResponse($questionnaireResponsesId: Float!, $response: QuestionResponseInput!) {
  saveResponse(
    questionnaireResponsesId: $questionnaireResponsesId
    response: $response
  ) {
    id
    responses {
      questionId
      response {
        value
      }
    }
  }
}
    `;
export type SaveResponseMutationFn = Apollo.MutationFunction<SaveResponseMutation, SaveResponseMutationVariables>;

/**
 * __useSaveResponseMutation__
 *
 * To run a mutation, you first call `useSaveResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResponseMutation, { data, loading, error }] = useSaveResponseMutation({
 *   variables: {
 *      questionnaireResponsesId: // value for 'questionnaireResponsesId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useSaveResponseMutation(baseOptions?: Apollo.MutationHookOptions<SaveResponseMutation, SaveResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveResponseMutation, SaveResponseMutationVariables>(SaveResponseDocument, options);
      }
export type SaveResponseMutationHookResult = ReturnType<typeof useSaveResponseMutation>;
export type SaveResponseMutationResult = Apollo.MutationResult<SaveResponseMutation>;
export type SaveResponseMutationOptions = Apollo.BaseMutationOptions<SaveResponseMutation, SaveResponseMutationVariables>;
export const SetSetupIntentDocument = gql`
    mutation setSetupIntent {
  setupIntent {
    clientSecret
  }
}
    `;
export type SetSetupIntentMutationFn = Apollo.MutationFunction<SetSetupIntentMutation, SetSetupIntentMutationVariables>;

/**
 * __useSetSetupIntentMutation__
 *
 * To run a mutation, you first call `useSetSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSetupIntentMutation, { data, loading, error }] = useSetSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<SetSetupIntentMutation, SetSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSetupIntentMutation, SetSetupIntentMutationVariables>(SetSetupIntentDocument, options);
      }
export type SetSetupIntentMutationHookResult = ReturnType<typeof useSetSetupIntentMutation>;
export type SetSetupIntentMutationResult = Apollo.MutationResult<SetSetupIntentMutation>;
export type SetSetupIntentMutationOptions = Apollo.BaseMutationOptions<SetSetupIntentMutation, SetSetupIntentMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($paymentMethodId: String!) {
  deletePaymentMethod(sourceId: $paymentMethodId) {
    error
  }
}
    `;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, options);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation CreatePaymentIntent($productIds: [String!]!) {
  createPaymentIntent(productIds: $productIds) {
    clientSecret
    id
    totalPrice
  }
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const ApplyDiscountDocument = gql`
    mutation ApplyDiscount($code: String!, $paymentIntentId: String!) {
  applyDiscountCode(code: $code, paymentIntentId: $paymentIntentId) {
    clientSecret
    id
    totalPrice
    discountAmount
    error
  }
}
    `;
export type ApplyDiscountMutationFn = Apollo.MutationFunction<ApplyDiscountMutation, ApplyDiscountMutationVariables>;

/**
 * __useApplyDiscountMutation__
 *
 * To run a mutation, you first call `useApplyDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyDiscountMutation, { data, loading, error }] = useApplyDiscountMutation({
 *   variables: {
 *      code: // value for 'code'
 *      paymentIntentId: // value for 'paymentIntentId'
 *   },
 * });
 */
export function useApplyDiscountMutation(baseOptions?: Apollo.MutationHookOptions<ApplyDiscountMutation, ApplyDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyDiscountMutation, ApplyDiscountMutationVariables>(ApplyDiscountDocument, options);
      }
export type ApplyDiscountMutationHookResult = ReturnType<typeof useApplyDiscountMutation>;
export type ApplyDiscountMutationResult = Apollo.MutationResult<ApplyDiscountMutation>;
export type ApplyDiscountMutationOptions = Apollo.BaseMutationOptions<ApplyDiscountMutation, ApplyDiscountMutationVariables>;
export const PurchaseProductDocument = gql`
    mutation PurchaseProduct($paymentIntentId: String!, $paymentMethodId: String!) {
  purchaseProduct(
    paymentIntentId: $paymentIntentId
    paymentMethodId: $paymentMethodId
  ) {
    clientSecret
  }
}
    `;
export type PurchaseProductMutationFn = Apollo.MutationFunction<PurchaseProductMutation, PurchaseProductMutationVariables>;

/**
 * __usePurchaseProductMutation__
 *
 * To run a mutation, you first call `usePurchaseProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseProductMutation, { data, loading, error }] = usePurchaseProductMutation({
 *   variables: {
 *      paymentIntentId: // value for 'paymentIntentId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function usePurchaseProductMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseProductMutation, PurchaseProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseProductMutation, PurchaseProductMutationVariables>(PurchaseProductDocument, options);
      }
export type PurchaseProductMutationHookResult = ReturnType<typeof usePurchaseProductMutation>;
export type PurchaseProductMutationResult = Apollo.MutationResult<PurchaseProductMutation>;
export type PurchaseProductMutationOptions = Apollo.BaseMutationOptions<PurchaseProductMutation, PurchaseProductMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($options: RegisterInput!) {
  register(options: $options) {
    error
    idToken
    user {
      firstName
      firebaseId
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const VerifyIdentityDocument = gql`
    mutation verifyIdentity {
  verifyIdentity {
    id
    updatedAt
    createdAt
    status
  }
}
    `;
export type VerifyIdentityMutationFn = Apollo.MutationFunction<VerifyIdentityMutation, VerifyIdentityMutationVariables>;

/**
 * __useVerifyIdentityMutation__
 *
 * To run a mutation, you first call `useVerifyIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyIdentityMutation, { data, loading, error }] = useVerifyIdentityMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyIdentityMutation(baseOptions?: Apollo.MutationHookOptions<VerifyIdentityMutation, VerifyIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyIdentityMutation, VerifyIdentityMutationVariables>(VerifyIdentityDocument, options);
      }
export type VerifyIdentityMutationHookResult = ReturnType<typeof useVerifyIdentityMutation>;
export type VerifyIdentityMutationResult = Apollo.MutationResult<VerifyIdentityMutation>;
export type VerifyIdentityMutationOptions = Apollo.BaseMutationOptions<VerifyIdentityMutation, VerifyIdentityMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($options: UpdateProfileInput!) {
  updateProfile(options: $options) {
    id
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($id: String!) {
  verifyEmail(id: $id)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const BloodTestOrdersDocument = gql`
    query BloodTestOrders($options: PagedQueryInput) {
  bloodTestOrders(options: $options) {
    results {
      createdAt
      id
      tests {
        status
      }
      fulfillmentOrders {
        status
      }
      pathwayRecord {
        user {
          id
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useBloodTestOrdersQuery__
 *
 * To run a query within a React component, call `useBloodTestOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBloodTestOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBloodTestOrdersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBloodTestOrdersQuery(baseOptions?: Apollo.QueryHookOptions<BloodTestOrdersQuery, BloodTestOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BloodTestOrdersQuery, BloodTestOrdersQueryVariables>(BloodTestOrdersDocument, options);
      }
export function useBloodTestOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BloodTestOrdersQuery, BloodTestOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BloodTestOrdersQuery, BloodTestOrdersQueryVariables>(BloodTestOrdersDocument, options);
        }
export type BloodTestOrdersQueryHookResult = ReturnType<typeof useBloodTestOrdersQuery>;
export type BloodTestOrdersLazyQueryHookResult = ReturnType<typeof useBloodTestOrdersLazyQuery>;
export type BloodTestOrdersQueryResult = Apollo.QueryResult<BloodTestOrdersQuery, BloodTestOrdersQueryVariables>;
export const BloodTestOrderDetailsDocument = gql`
    query BloodTestOrderDetails($orderId: Float!) {
  bloodTestOrderDetails(orderId: $orderId) {
    tests {
      id
      status
    }
    fulfillmentOrders {
      id
      status
    }
  }
}
    `;

/**
 * __useBloodTestOrderDetailsQuery__
 *
 * To run a query within a React component, call `useBloodTestOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBloodTestOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBloodTestOrderDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useBloodTestOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<BloodTestOrderDetailsQuery, BloodTestOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BloodTestOrderDetailsQuery, BloodTestOrderDetailsQueryVariables>(BloodTestOrderDetailsDocument, options);
      }
export function useBloodTestOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BloodTestOrderDetailsQuery, BloodTestOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BloodTestOrderDetailsQuery, BloodTestOrderDetailsQueryVariables>(BloodTestOrderDetailsDocument, options);
        }
export type BloodTestOrderDetailsQueryHookResult = ReturnType<typeof useBloodTestOrderDetailsQuery>;
export type BloodTestOrderDetailsLazyQueryHookResult = ReturnType<typeof useBloodTestOrderDetailsLazyQuery>;
export type BloodTestOrderDetailsQueryResult = Apollo.QueryResult<BloodTestOrderDetailsQuery, BloodTestOrderDetailsQueryVariables>;
export const AvailabilitiesDocument = gql`
    query availabilities($options: QueryAvailabilitiesInput!) {
  availabilities(options: $options) {
    start
    end
    doctorId
  }
}
    `;

/**
 * __useAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitiesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAvailabilitiesQuery(baseOptions: Apollo.QueryHookOptions<AvailabilitiesQuery, AvailabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailabilitiesQuery, AvailabilitiesQueryVariables>(AvailabilitiesDocument, options);
      }
export function useAvailabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailabilitiesQuery, AvailabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailabilitiesQuery, AvailabilitiesQueryVariables>(AvailabilitiesDocument, options);
        }
export type AvailabilitiesQueryHookResult = ReturnType<typeof useAvailabilitiesQuery>;
export type AvailabilitiesLazyQueryHookResult = ReturnType<typeof useAvailabilitiesLazyQuery>;
export type AvailabilitiesQueryResult = Apollo.QueryResult<AvailabilitiesQuery, AvailabilitiesQueryVariables>;
export const ConsultationDocument = gql`
    query consultation($id: Float, $options: ConsultationQueryOptions) {
  consultation(id: $id, options: $options) {
    id
    pathwayStepUid
    pathwayRecordId
    appointment {
      ...AppointmentFields
    }
  }
}
    ${AppointmentFieldsFragmentDoc}`;

/**
 * __useConsultationQuery__
 *
 * To run a query within a React component, call `useConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useConsultationQuery(baseOptions?: Apollo.QueryHookOptions<ConsultationQuery, ConsultationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultationQuery, ConsultationQueryVariables>(ConsultationDocument, options);
      }
export function useConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationQuery, ConsultationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultationQuery, ConsultationQueryVariables>(ConsultationDocument, options);
        }
export type ConsultationQueryHookResult = ReturnType<typeof useConsultationQuery>;
export type ConsultationLazyQueryHookResult = ReturnType<typeof useConsultationLazyQuery>;
export type ConsultationQueryResult = Apollo.QueryResult<ConsultationQuery, ConsultationQueryVariables>;
export const PathwaysDocument = gql`
    query pathways {
  pathways {
    uid
    name
    steps {
      uid
      name
      events
      waitFor {
        stepUid
        events
      }
    }
  }
}
    `;

/**
 * __usePathwaysQuery__
 *
 * To run a query within a React component, call `usePathwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwaysQuery({
 *   variables: {
 *   },
 * });
 */
export function usePathwaysQuery(baseOptions?: Apollo.QueryHookOptions<PathwaysQuery, PathwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwaysQuery, PathwaysQueryVariables>(PathwaysDocument, options);
      }
export function usePathwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwaysQuery, PathwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwaysQuery, PathwaysQueryVariables>(PathwaysDocument, options);
        }
export type PathwaysQueryHookResult = ReturnType<typeof usePathwaysQuery>;
export type PathwaysLazyQueryHookResult = ReturnType<typeof usePathwaysLazyQuery>;
export type PathwaysQueryResult = Apollo.QueryResult<PathwaysQuery, PathwaysQueryVariables>;
export const PathwayRecordDocument = gql`
    query pathwayRecord($pathwayUid: String!) {
  pathwayRecord(pathwayUid: $pathwayUid) {
    id
    createdAt
    updatedAt
    pathwayUid
    events {
      id
      stepUid
      createdAt
      type
      data
    }
  }
}
    `;

/**
 * __usePathwayRecordQuery__
 *
 * To run a query within a React component, call `usePathwayRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayRecordQuery({
 *   variables: {
 *      pathwayUid: // value for 'pathwayUid'
 *   },
 * });
 */
export function usePathwayRecordQuery(baseOptions: Apollo.QueryHookOptions<PathwayRecordQuery, PathwayRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayRecordQuery, PathwayRecordQueryVariables>(PathwayRecordDocument, options);
      }
export function usePathwayRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayRecordQuery, PathwayRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayRecordQuery, PathwayRecordQueryVariables>(PathwayRecordDocument, options);
        }
export type PathwayRecordQueryHookResult = ReturnType<typeof usePathwayRecordQuery>;
export type PathwayRecordLazyQueryHookResult = ReturnType<typeof usePathwayRecordLazyQuery>;
export type PathwayRecordQueryResult = Apollo.QueryResult<PathwayRecordQuery, PathwayRecordQueryVariables>;
export const QuestionnaireDocument = gql`
    query questionnaire($questionnaireUid: String!) {
  questionnaire(questionnaireUid: $questionnaireUid) {
    uid
    questions {
      id
      title
      description
      type
      options {
        value
        label
        questions {
          id
          title
          description
          type
          options {
            value
            label
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireQuery({
 *   variables: {
 *      questionnaireUid: // value for 'questionnaireUid'
 *   },
 * });
 */
export function useQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, options);
      }
export function useQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, options);
        }
export type QuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireQuery>;
export type QuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireLazyQuery>;
export type QuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireQuery, QuestionnaireQueryVariables>;
export const QuestionnaireResponsesDocument = gql`
    query questionnaireResponses($questionnaireResponsesId: Float!) {
  questionnaireResponses(questionnaireResponsesId: $questionnaireResponsesId) {
    id
    completedAt
    questionnaireUid
    pathwayStepUid
    pathwayRecordId
    responses {
      questionId
      response {
        value
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireResponsesQuery__
 *
 * To run a query within a React component, call `useQuestionnaireResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireResponsesQuery({
 *   variables: {
 *      questionnaireResponsesId: // value for 'questionnaireResponsesId'
 *   },
 * });
 */
export function useQuestionnaireResponsesQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireResponsesQuery, QuestionnaireResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireResponsesQuery, QuestionnaireResponsesQueryVariables>(QuestionnaireResponsesDocument, options);
      }
export function useQuestionnaireResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireResponsesQuery, QuestionnaireResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireResponsesQuery, QuestionnaireResponsesQueryVariables>(QuestionnaireResponsesDocument, options);
        }
export type QuestionnaireResponsesQueryHookResult = ReturnType<typeof useQuestionnaireResponsesQuery>;
export type QuestionnaireResponsesLazyQueryHookResult = ReturnType<typeof useQuestionnaireResponsesLazyQuery>;
export type QuestionnaireResponsesQueryResult = Apollo.QueryResult<QuestionnaireResponsesQuery, QuestionnaireResponsesQueryVariables>;
export const SearchQuestionnaireResponsesDocument = gql`
    query searchQuestionnaireResponses($searchOptions: QuestionnaireResponsesSearchOptions!) {
  questionnaireResponses(searchOptions: $searchOptions) {
    id
    completedAt
    questionnaireUid
    pathwayStepUid
    pathwayRecordId
    responses {
      questionId
      response {
        value
      }
    }
  }
}
    `;

/**
 * __useSearchQuestionnaireResponsesQuery__
 *
 * To run a query within a React component, call `useSearchQuestionnaireResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuestionnaireResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuestionnaireResponsesQuery({
 *   variables: {
 *      searchOptions: // value for 'searchOptions'
 *   },
 * });
 */
export function useSearchQuestionnaireResponsesQuery(baseOptions: Apollo.QueryHookOptions<SearchQuestionnaireResponsesQuery, SearchQuestionnaireResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuestionnaireResponsesQuery, SearchQuestionnaireResponsesQueryVariables>(SearchQuestionnaireResponsesDocument, options);
      }
export function useSearchQuestionnaireResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuestionnaireResponsesQuery, SearchQuestionnaireResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuestionnaireResponsesQuery, SearchQuestionnaireResponsesQueryVariables>(SearchQuestionnaireResponsesDocument, options);
        }
export type SearchQuestionnaireResponsesQueryHookResult = ReturnType<typeof useSearchQuestionnaireResponsesQuery>;
export type SearchQuestionnaireResponsesLazyQueryHookResult = ReturnType<typeof useSearchQuestionnaireResponsesLazyQuery>;
export type SearchQuestionnaireResponsesQueryResult = Apollo.QueryResult<SearchQuestionnaireResponsesQuery, SearchQuestionnaireResponsesQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods {
  paymentMethods {
    paymentMethods {
      id
      card {
        exp_month
        exp_year
        brand
        last4
      }
    }
  }
}
    `;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const ProductDocument = gql`
    query product($alias: String!) {
  product(alias: $alias) {
    ... on ProductModel {
      id
      name
      alias
      metadata
      caption
      description
      images
      object
      price {
        currency
        unit_amount
        unit_amount_decimal
      }
      url
    }
    ... on ResponseWithError {
      error
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    firstName
    lastName
    email
    firebaseId
    stripeId
    dob
    shippingAddress {
      address1
      address2
      city
      postalCode
    }
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const IdentityChecksDocument = gql`
    query identityChecks {
  self {
    identityChecks {
      id
      status
      result
      updatedAt
    }
  }
}
    `;

/**
 * __useIdentityChecksQuery__
 *
 * To run a query within a React component, call `useIdentityChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityChecksQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityChecksQuery(baseOptions?: Apollo.QueryHookOptions<IdentityChecksQuery, IdentityChecksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdentityChecksQuery, IdentityChecksQueryVariables>(IdentityChecksDocument, options);
      }
export function useIdentityChecksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdentityChecksQuery, IdentityChecksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdentityChecksQuery, IdentityChecksQueryVariables>(IdentityChecksDocument, options);
        }
export type IdentityChecksQueryHookResult = ReturnType<typeof useIdentityChecksQuery>;
export type IdentityChecksLazyQueryHookResult = ReturnType<typeof useIdentityChecksLazyQuery>;
export type IdentityChecksQueryResult = Apollo.QueryResult<IdentityChecksQuery, IdentityChecksQueryVariables>;
export const OnfidoSdkTokenDocument = gql`
    query onfidoSdkToken {
  onfidoSdkToken
}
    `;

/**
 * __useOnfidoSdkTokenQuery__
 *
 * To run a query within a React component, call `useOnfidoSdkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnfidoSdkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnfidoSdkTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnfidoSdkTokenQuery(baseOptions?: Apollo.QueryHookOptions<OnfidoSdkTokenQuery, OnfidoSdkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnfidoSdkTokenQuery, OnfidoSdkTokenQueryVariables>(OnfidoSdkTokenDocument, options);
      }
export function useOnfidoSdkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnfidoSdkTokenQuery, OnfidoSdkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnfidoSdkTokenQuery, OnfidoSdkTokenQueryVariables>(OnfidoSdkTokenDocument, options);
        }
export type OnfidoSdkTokenQueryHookResult = ReturnType<typeof useOnfidoSdkTokenQuery>;
export type OnfidoSdkTokenLazyQueryHookResult = ReturnType<typeof useOnfidoSdkTokenLazyQuery>;
export type OnfidoSdkTokenQueryResult = Apollo.QueryResult<OnfidoSdkTokenQuery, OnfidoSdkTokenQueryVariables>;