const typography = {
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 700,
  },

  fonts: {
    body: 'Cerebri Sans, system-ui, sans-serif',
    heading: 'Made Outer Sans',
  },

  fontSizes: {
    sm: '12.8px',
    base: '17px',
    md: '20px',
    lg: '26px',
    xl: '48px',
    xxl: '72px',
  },
};

export type Typography = typeof typography;

export default typography;
