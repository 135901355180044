import { Heading, Text } from '@chakra-ui/react';

export const H1 = (props) => (
  <Heading as='h1' fontSize='xl' fontWeight='100' {...props} />
);

export const H2 = (props) => (
  <Heading as='h2' fontSize='lg' fontWeight='100' {...props} />
);

export const H3 = (props) => (
  <Heading as='h3' fontSize='md' fontWeight='100' {...props} />
);

export const H4 = (props) => (
  <Heading as='h4' fontSize='md' fontWeight='100' {...props} />
);

export const P = (props) => <Text color='white.70' {...props} />;

export const ErrorText = (props) => <Text color='red.400' {...props} />;

export const Subtitle = (props) => (
  <Text
    color='white.70'
    fontSize='16px'
    fontWeight={600}
    letterSpacing='8%'
    lineHeight='24px'
    {...props}
  />
);

export const BigTitle = (props) => <H1 as='h2' {...props} />;

export const Title = (props) => (
  <H3 fontSize='22px' fontWeight='300' lineHeight='28px' {...props} />
);

export const Description = (props) => (
  <Text
    color='white.70'
    fontSize='17px'
    fontWeight={400}
    letterSpacing='-2%'
    lineHeight='28px'
    {...props}
  />
);

export const PriceTag = (props) => {
  const { discounted } = props;

  return discounted ? (
    <H2
      as='s'
      color='white.70'
      fontSize='26px'
      fontWeight='250px'
      lineHeight='32px'
      {...props}
    />
  ) : (
    <H2 fontSize='48px' fontWeight='250px' lineHeight='64px' {...props} />
  );
};
