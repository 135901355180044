import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Loader, LoaderOptions } from 'google-maps';

import { ShippingAddress } from '@/generated/core.graphql';

import GeocoderResult = google.maps.GeocoderResult;

interface IGoogleMapContext {
  getAddressByPostCode: (
    code: string,
    houseNo?: string
  ) => Promise<ShippingAddress | undefined>;
}

const GoogleMapContext = React.createContext<IGoogleMapContext>({
  getAddressByPostCode: () => Promise.resolve(undefined),
});

function GoogleMapsProvider({ children }: { children: React.ReactNode }) {
  const [client, setClient] = useState<any>();

  useEffect(() => {
    (async () => {
      const options: LoaderOptions = { region: 'uk' };
      const loader = new Loader(
        process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
        options
      );
      const google = await loader.load();

      setClient(google);
    })();
  }, []);

  const mapGeocodeResultToAddress = (
    data: GeocoderResult,
    houseNo?: string
  ): ShippingAddress => {
    const address: ShippingAddress = {
      address1: '',
      address2: '',
      city: '',
      postalCode: '',
    };

    data.address_components.forEach((c) => {
      if (c.types.includes('route')) {
        address.address1 = `${houseNo ? `${houseNo} ` : ''}${c.short_name}`;
      }
      if (c.types.includes('postal_town')) {
        address.city = c.short_name;
      }
      if (c.types.includes('postal_code')) {
        address.postalCode = c.short_name;
      }
    });

    return address;
  };

  const getAddressByPostCode = useCallback(
    async (
      code: string,
      houseNo?: string
    ): Promise<ShippingAddress | undefined> => {
      if (!client) {
        return;
      }
      try {
        const geocoder = await new google.maps.Geocoder();
        let address: ShippingAddress | undefined;

        await geocoder.geocode(
          { address: '', componentRestrictions: { postalCode: code } },
          (result) => {
            if (result?.length) {
              address = mapGeocodeResultToAddress(result[0], houseNo);
            }
          }
        );

        return address;
      } catch (err) {
        return undefined;
      }
    },
    [client]
  );

  return (
    <GoogleMapContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getAddressByPostCode,
      }}
    >
      {children}
    </GoogleMapContext.Provider>
  );
}

function useGoogleMaps() {
  const context = React.useContext(GoogleMapContext);
  if (context === undefined) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider.');
  }
  return context;
}

export { GoogleMapsProvider, useGoogleMaps };
