/* eslint-disable no-underscore-dangle */
import { Box, Stack } from '@chakra-ui/react';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import { IStoryblokComponentProps } from '@/types/storyblok';

const Grid = ({ blok }: IStoryblokComponentProps): JSX.Element => (
  <Stack direction={['column', 'row']} spacing={4}>
    {(blok.columns as SbBlokData[]).map((nestedBlok) => (
      <Box>
        <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
      </Box>
    ))}
  </Stack>
);

export default Grid;
