import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { signIn as nextSignIn, signOut as nextSignOut } from 'next-auth/react';

import {
  useRegisterUserMutation,
  UserModel,
  UserRole,
  useSelfLazyQuery,
} from '@/generated/core.graphql';
import useCustomSession from '@/hooks/customSession';
import { CustomSession } from '@/server/models/auth';

interface IAuthContext {
  isLoading: boolean;
  isAuthenticated: boolean;
  isSigningIn: boolean;
  isRegistering: boolean;
  session?: CustomSession;
  self?: UserModel | undefined;
  selfError?: string;
  roles?: UserRole[] | undefined;
  signInError?: string;
  registerError?: string;
  signIn: (email: string, password: string) => Promise<boolean>;
  register: (options: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    dob: string;
  }) => Promise<boolean>;
  signOut: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
  isLoading: true,
  isAuthenticated: false,
  isSigningIn: false,
  isRegistering: false,
  signIn: () => Promise.resolve(false),
  register: () => Promise.resolve(false),
  signOut: () => null,
});

function AuthProvider({
  children,
  clearCache,
}: {
  clearCache: () => Promise<void>;
  children: React.ReactNode;
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [signInError, setSignInError] = useState<string | undefined>('');
  const [registerError, setRegisterError] = useState<string | undefined>('');

  const session = useCustomSession();
  const sessionUser = session.data?.user;

  const [getSelf, { data: selfData, error: selfError }] = useSelfLazyQuery();

  const [registerUserMutation] = useRegisterUserMutation();

  useEffect(() => {
    setIsAuthenticated(!!sessionUser);
  }, [sessionUser]);

  useEffect(() => {
    if (isAuthenticated && !selfData?.self) {
      getSelf();
    }
  }, [isAuthenticated, selfData, getSelf]);

  const signIn = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      setSignInError(undefined);
      setIsSigningIn(true);

      try {
        const result = await nextSignIn('credentials', {
          username: email,
          password,
          // handle signin response without redirect
          // (available only for credentials and email providers)
          redirect: false,
        }); // adds CSRF token automatically
        // eslint-disable-next-line no-console
        console.log('signin response:', result);

        if (!result.ok || result.error) {
          setSignInError('Invalid credentials. Check your email or password.');
          return false;
        }

        await getSelf();
        return true;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('signin error:', err);
        setSignInError(err.message);
        return false;
      } finally {
        setIsSigningIn(false);
      }
    },
    []
  );

  const register = useCallback(
    async ({ email, firstName, lastName, password, dob }): Promise<boolean> => {
      try {
        setRegisterError('');
        setIsRegistering(true);

        const response = await registerUserMutation({
          variables: {
            options: {
              email,
              firstName,
              lastName,
              password,
              dob,
            },
          },
        });

        if (response?.data.register.error) {
          setRegisterError(response?.data.register.error);
          return false;
        }
        await signIn(email, password);
        return true;
      } catch (err) {
        setRegisterError(err.message);
        return false;
      } finally {
        setIsRegistering(false);
      }
    },
    []
  );

  const signOut = useCallback(async () => {
    try {
      await nextSignOut({
        redirect: false, // stay on this page
      });
      await clearCache();
      // eslint-disable-next-line no-console
      console.log('signed out.');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('signout error:', err);
    }
  }, []);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLoading: session.isLoading || isSigningIn,
        isAuthenticated,
        isSigningIn,
        isRegistering,
        session: session.data,
        self: selfData?.self as UserModel,
        selfError: selfError?.message,
        roles: sessionUser?.roles || [],
        signInError,
        registerError,
        signIn,
        register,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }
  return context;
}

export { AuthProvider, useAuth };
