export default {
  variants: {
    line: {
      tablist: {
        color: 'white.50',
        borderColor: 'white.10',
        marginBotom: '56px',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        '::-webkit-scrollbar-thumb': {
          display: 'none',
        },
        '::-webkit-scrollbar-track': {
          display: 'none',
        },
      },
      tab: {
        fontSize: '15px',
        py: '26px',
        px: '66px',
        _selected: {
          color: 'white',
          borderColor: 'brand.accent',
        },
        _focus: {
          outline: 'none',
          boxShadow: 'none',
        },
        _active: {
          bg: 'none',
        },
      },
    },
  },
};
