/* eslint-disable import/prefer-default-export */
import { ReactNode } from 'react';

import {
  chakra,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-named-default
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = {
  children: JSX.Element | ReactNode | string;
  key?: string;
} & ChakraLinkProps &
  NextLinkProps;

/**
 * Internal Link element based on
 * @see https://chakra-ui.com/docs/components/link#usage-with-nextjs
 */
export const Link = chakra(
  ({
    children,
    key,
    href,
    scroll,
    replace,
    locale,
    ...restProps
  }: Partial<React.PropsWithChildren<LinkProps>>): JSX.Element => (
    <NextLink
      key={key}
      href={href}
      locale={locale}
      passHref
      replace={replace}
      scroll={scroll}
    >
      <ChakraLink {...restProps}>{children}</ChakraLink>
    </NextLink>
  )
);
