/* eslint-disable no-underscore-dangle */
import { Box } from '@chakra-ui/react';
import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from '@storyblok/react';

import { IStoryblokComponentProps } from '@/types/storyblok.d';

const SBPage = ({ blok }: IStoryblokComponentProps): JSX.Element => (
  <Box {...storyblokEditable(blok)} key={blok._uid}>
    {(blok.body as SbBlokData[]).map((nestedBlok) => (
      <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  </Box>
);

export default SBPage;
