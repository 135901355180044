import { Icon } from '@chakra-ui/react';

const ChevronLeft = (props) => {
  return (
    <Icon fill='none' viewBox='0 0 12 20' {...props}>
      <path d='M11 1L2 10L11 19' stroke='currentColor' strokeWidth='1.5' />
    </Icon>
  );
};

export default ChevronLeft;
