import { SbReactComponentsMap } from '@storyblok/react';

import SBGrid from './SBGrid';
import SBPage from './SBPage';
import SBRichText from './SBRichText';
import SBTeaser from './SBTeaser';

const SBComponentsMapping: SbReactComponentsMap = {
  grid: SBGrid,
  page: SBPage,
  teaser: SBTeaser,
  richText: SBRichText,
};

export default SBComponentsMapping;
